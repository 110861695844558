<template>
    <n-data-table :columns="columns" :data="data" size="small"></n-data-table>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from 'vue';
import { NDataTable } from 'naive-ui';

const props = defineProps({
    message: {
        type: Object,
        default() {
            return {};
        },
    },
});
const emits = defineEmits(['ready']);

let columns = computed(() => {
    return props.message.column_names.map((item, index) => {
        return { title: item, key: `column${index}`, minWidth: 100 };
    });
});

let data = computed(() => {
    return props.message.sql_res.map(item => {
        let obj = {};
        for (let i = 0; i < item.length; i++) {
            obj[`column${i}`] = item[i];
        }
        return obj;
    });
});

onMounted(() => {
    emits('ready');
});
</script>
