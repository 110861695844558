<template>
    <div class="gpt-chart-view" :style="{ height: viewHeight + 'px' }">
        <PageTitle name="GPT报表生产(demo)"></PageTitle>
        <div ref="chatLineRef" class="chat-line">
            <div
                v-for="(item, index) in chatMessageLine"
                :class="{ message: true, self: item.self }"
            >
                <div class="avatar"></div>
                <div class="message-pop">
                    <div class="content">
                        <template v-if="item.type === 'string'">
                            {{ item.message }}
                        </template>
                        <template
                            v-else-if="
                                item.type.indexOf('line') > -1 ||
                                item.type.indexOf('bar') > -1
                            "
                        >
                            <ChartContent
                                :message="item.message"
                                @ready="onMessageContentReady"
                            />
                        </template>
                        <template v-else>
                            <TableContent
                                :message="item.message"
                                @ready="onMessageContentReady"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-area">
            <n-input
                v-model:value="selfMessage"
                type="textarea"
                placeholder="输入prompt，已支持“Command+Enter”发送"
                :autosize="{
                    minRows: 5,
                    maxRows: 5,
                }"
                @keydown="onInputKeyDown"
            ></n-input>
            <div class="submit-view">
                <n-button type="primary" @click="postMessage">发送</n-button>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.gpt-chart-view {
    .common-content;
    position: relative;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    .chat-line {
        flex-grow: 1;
        padding: 10px;
        overflow-y: auto;
        border-top: @standard-border-values;

        .message {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            .avatar {
                width: 40px;
                height: 40px;
                background-color: #3f51b5;
                border-radius: 20px;
            }

            .message-pop {
                background-color: #fff;
                // min-width: 200px;
                max-width: 80%;
                overflow-x: auto;
                padding: 10px 16px;
                border: @standard-border-values;
                border-radius: 8px;
                margin-left: 15px;
                .standard-shadow;
            }
        }

        .self {
            flex-direction: row-reverse;

            .message-pop {
                margin-right: 15px;
                background-color: #66ccff66;
            }

            .avatar {
                background-color: #aaa;
            }
        }
    }

    .input-area {
        flex-shrink: 0;
        position: relative;

        .submit-view {
            position: absolute;
            bottom: 2px;
            right: 0;
        }
    }

    .tip {
        position: absolute;
        top: 40px;
        padding: 10px;
        border: 1px solid red;
    }
}
</style>

<script setup>
import { ref, onMounted, nextTick } from 'vue';

import { NInput, NButton, useMessage } from 'naive-ui';

import PageTitle from '@/components/PageTitle';

import TableContent from './components/TableContent';

import ChartContent from './components/ChartContent';
import { debounce } from 'lodash';
import { postGPTtoSQL } from '@/common/API';

const message = useMessage();

let viewHeight = ref(document.body.clientHeight - 71);

window.addEventListener('resize', () => {
    viewHeight.value = document.body.clientHeight - 71;
});

let chatLineRef = ref();

let chatMessageLine = ref([]);

let selfMessage = ref('');

const onMessageContentReady = debounce(() => {
    chatLineRef.value.scrollTo({ top: 99999, behavior: 'smooth' });
}, 400);

function postMessage() {
    chatMessageLine.value.push({
        self: true,
        type: 'string',
        message: selfMessage.value,
    });
    nextTick(() => {
        chatLineRef.value.scrollTo({ top: 99999, behavior: 'smooth' });
    });
    postGPTtoSQL(selfMessage.value)
        .then(res => {
            if (res.error_no !== 0) {
                message.error('未知原因导致消息发送失败');
                return;
            }
            setTimeout(() => {
                chatMessageLine.value.push({
                    self: false,
                    type: res.data.type,
                    message: res.data,
                });
                nextTick(() => {
                    chatLineRef.value.scrollTo({
                        top: 99999,
                        behavior: 'smooth',
                    });
                });
            }, 1000);
        })
        .catch(err => {
            console.log(err);
            message.error('未知原因导致消息发送失败');
        });
    selfMessage.value = '';
}

let isCommandStart = null;

function onInputKeyDown(keyboardEvent) {
    if (isCommandStart && keyboardEvent.key === 'Enter') {
        postMessage();
        cmdSign = null;
        return;
    }
    isCommandStart = keyboardEvent.key === 'Meta';
}

onMounted(() => {
    message.info(
        '该功能为基于chatGPT的演示功能，目前可查询数据源是一张学生成绩表，表内数据包括学生id，学生姓名，学生学科和学生成绩。您可以询问和学生有关的数据，比如学生人数，学生各科成绩，以及学科成绩大于60分的学生名单等',
        { closable: true, duration: 0 }
    );
});
</script>
