<template>
    <div ref="chartRef" class="chart-view"></div>
</template>

<style lang="less" scoped>
.chart-view {
    width: 600px;
    height: 300px;
}
</style>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

const props = defineProps({
    message: {
        type: Object,
        // default() {
        //     return { type: 'line' };
        // },
    },
});

const emits = defineEmits(['ready']);

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    BarChart,
    UniversalTransition,
]);

let chartRef = ref();

let chartInstance = null;

onMounted(() => {
    chartInstance = echarts.init(chartRef.value);

    console.log(props.message);

    let xAxisData = props.message.sql_res.map(item => {
        console.log(item);
        return item[0];
    });
    let seriesData = props.message.sql_res.map(item => item[1]);

    console.log(xAxisData);

    chartInstance.setOption({
        grid: {
            top: 20,
            left: 46,
            right: 20,
            bottom: 25,
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
        },
        tooltip: {
            trigger: 'axis',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: seriesData,
                type: props.message.type.indexOf('line') > -1 ? 'line' : 'bar',
            },
        ],
    });
    emits('ready');
});
</script>
